import PropTypes from 'prop-types';
import React from 'react';

import HamburgerIcon from './components/hamburger-icon';

const Icon = ({ symbol }) => {
  switch (symbol) {
    case 'hamburger':
      return <HamburgerIcon />;
    default:
      return <span>Unknown icon: {symbol}</span>;
  }
};

Icon.propTypes = {
  symbol: PropTypes.string.isRequired,
};

export default Icon;
