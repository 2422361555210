import React from 'react';

import Header from '../header';

const layout = ({ backgroundColour, children, isHomepage, menuItems, onHideNav, onShowNav, showNav, siteTitle }) => (
  <>
    <Header
      menuItems={menuItems}
      siteTitle={siteTitle}
      onHideNav={onHideNav}
      onShowNav={onShowNav}
      showNav={showNav}
      backgroundColour={backgroundColour}
      isHomepage={isHomepage}
    />
    {children}
  </>
);

export default layout;
