import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { anchor } from './index.module.scss';

const Anchor = ({ className, children, ...props }) => (
  <a {...props} className={classNames(anchor, className)}>
    {children}
  </a>
);

Anchor.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Anchor.defaultProps = {
  className: '',
};

export default Anchor;
