import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import {
  branding,
  darkLogo,
  darkStyle,
  homeRoot,
  lightLogo,
  mLogo,
  nav,
  root,
  showNavStyle,
  toggleNavButton,
  wrapper,
} from './index.module.scss';
import CTALink from '../cta-link';
import GatsbyLink from '../gatsby-link';
import Icon from '../icon';
import Logo from '../logo';
import mLogoLarge from '../../assets/images/large_m_symbol.svg';

const Menu = ({ dark, menuItems }) => (
  <ul>
    {_.map(menuItems, (navItem, index) => (
      <li key={index} className={dark ? darkStyle : {}}>
        <CTALink {...navItem} />
      </li>
    ))}
  </ul>
);

Menu.propTypes = {
  dark: PropTypes.bool.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const lightColours = ['white', 'pink', 'blue'];

const Header = ({ backgroundColour, isHomepage, menuItems, onHideNav, onShowNav, showNav }) => (
  <div className={isHomepage ? homeRoot : root}>
    <div className={wrapper}>
      <div className={branding}>
        <GatsbyLink to="/#home">
          <Logo dark={_.includes(lightColours, backgroundColour)} />
        </GatsbyLink>
      </div>
      <GatsbyLink to="/#home">
        <img
          className={classnames(mLogo, _.includes(lightColours, backgroundColour) ? darkLogo : lightLogo)}
          src={mLogoLarge}
          alt="Mint Design"
        />
      </GatsbyLink>
      <button
        type="button"
        className={classnames(toggleNavButton, _.includes(lightColours, backgroundColour) && darkStyle)}
        onClick={showNav ? onHideNav : onShowNav}
      >
        <Icon symbol="hamburger" />
      </button>
      <nav className={classnames(nav, showNav && showNavStyle)}>
        <Menu menuItems={menuItems} dark={_.includes(lightColours, backgroundColour)} />
      </nav>
    </div>
  </div>
);

Header.propTypes = {
  backgroundColour: PropTypes.string,
  isHomepage: PropTypes.bool,
  menuItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onHideNav: PropTypes.func,
  onShowNav: PropTypes.func,
  showNav: PropTypes.bool,
};

Header.defaultProps = {
  backgroundColour: '',
  isHomepage: false,
  onHideNav: _.noop,
  onShowNav: _.noop,
  showNav: false,
};

export default Header;
