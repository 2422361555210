import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Clickable from '../clickable';

const GatsbyLink = ({ children, to, activeClassName, partiallyActive, ...otherProps }) => {
  const internal = /^\/(?!\/)/.test(to);

  if (internal) {
    return (
      <Link to={to} activeClassName={activeClassName} partiallyActive={partiallyActive} {...otherProps}>
        {children}
      </Link>
    );
  }
  return (
    <Clickable to={to} {...otherProps}>
      {children}
    </Clickable>
  );
};

GatsbyLink.propTypes = {
  activeClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  partiallyActive: PropTypes.string,
  to: PropTypes.string,
};

GatsbyLink.defaultProps = {
  activeClassName: null,
  partiallyActive: null,
  to: null,
};

export default GatsbyLink;
