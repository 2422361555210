import PropTypes from 'prop-types';
import React from 'react';

import { darkLogo, lightLogo } from './index.module.scss';
import mintLogo from '../../assets/images/mint_full_logo.svg';

const Logo = ({ dark }) => <img src={mintLogo} className={dark ? darkLogo : lightLogo} alt="Mint Design" />;

Logo.propTypes = {
  dark: PropTypes.bool,
};

Logo.defaultProps = {
  dark: false,
};

export default Logo;
