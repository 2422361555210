const getBackgroundColour = (color) => {
  switch (color) {
    case 'grey':
      return 'bgGrey';
    case 'green':
      return 'bgGreen';
    case 'pink':
      return 'bgPink';
    case 'brown':
      return 'bgBrown';
    case 'blue':
      return 'bgBlue';
    case 'black':
      return 'bgBlack';
    case 'white':
    default:
      return 'bgWhite';
  }
};

export default getBackgroundColour;
