import { Link, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const doNavigate = (target) => {
  if (!target || !target.length) {
    return;
  }
  const internal = /^\/(?!\/)/.test(target);
  if (internal) {
    navigate(target);
  } else {
    window.location = target;
  }
};

const CTALink = ({ buttonActionClass, kind, landingPageRoute, link, route, title }) => {
  let ctaLink = route || link || '#';
  if (landingPageRoute && landingPageRoute.slug && landingPageRoute.slug.current) {
    ctaLink = landingPageRoute.slug.current;
  }

  if (kind === 'button') {
    return (
      <button type="button" id="navAction" onClick={() => doNavigate(ctaLink)} className={buttonActionClass || ''}>
        {title}
      </button>
    );
  }

  if (link) {
    return (
      <a href={`/${ctaLink}`} target="_blank" rel="noopener noreferrer">
        {title}
      </a>
    );
  }

  return <Link to={`/${ctaLink}`}>{title}</Link>;
};

CTALink.propTypes = {
  buttonActionClass: PropTypes.string,
  kind: PropTypes.string,
  landingPageRoute: PropTypes.shape({
    slug: PropTypes.shape({
      current: PropTypes.string,
    }),
  }),
  link: PropTypes.string,
  route: PropTypes.string,
  title: PropTypes.string.isRequired,
};

CTALink.defaultProps = {
  buttonActionClass: '',
  kind: undefined,
  landingPageRoute: undefined,
  link: undefined,
  route: undefined,
};

export default CTALink;
