import { graphql, StaticQuery } from 'gatsby';
import _ from 'lodash';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React from 'react';

import getBackgroundColour from './utils/get-background-colour';

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
      title
      description
      keywords
    }
  }
`;

const SEO = ({ backgroundColour, description, lang, meta, keywords, title }) => (
  <StaticQuery
    query={detailsQuery}
    render={(data) => {
      const metaDescription = description || (data.site && data.site.description) || '';
      const siteTitle = (data.site && data.site.title) || '';

      return (
        <Helmet
          htmlAttributes={{ lang }}
          bodyAttributes={{ class: getBackgroundColour(backgroundColour) }}
          title={title}
          titleTemplate={title === siteTitle ? '%s' : `%s | ${siteTitle}`}
          meta={[
            {
              content: metaDescription,
              name: 'description',
            },
            {
              content: title,
              property: 'og:title',
            },
            {
              content: metaDescription,
              property: 'og:description',
            },
            {
              content: 'website',
              property: 'og:type',
            },
          ]
            .concat(
              keywords && keywords.length > 0
                ? {
                    content: keywords.join(', '),
                    name: 'keywords',
                  }
                : [],
            )
            .concat(meta)}
        />
      );
    }}
  />
);

SEO.defaultProps = {
  backgroundColour: '',
  description: '',
  keywords: [],
  lang: 'en',
  meta: [],
};

SEO.propTypes = {
  backgroundColour: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  title: PropTypes.string.isRequired,
};

export default SEO;
