import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { unstyledButton } from './index.module.scss';
import Anchor from './components/anchor';

const buttonTypes = ['button', 'submit'];

const Clickable = ({ children, className, disabled, inNewTab, onClick, to, type }) => {
  if (disabled) {
    return (
      /* eslint-disable-next-line react/button-has-type */
      <button disabled className={classNames(unstyledButton, className)}>
        {children}
      </button>
    );
  }

  if (_.isString(to)) {
    const isMailTo = _.startsWith(to, 'mailto:');
    const newTabProps = inNewTab || isMailTo ? { rel: 'noopener noreferrer', target: '_blank' } : {};

    if (_.startsWith(to, 'http') || _.startsWith(to, '#') || isMailTo) {
      return (
        <Anchor href={to} className={className} {...newTabProps}>
          {children}
        </Anchor>
      );
    }
  }

  const elementPropsButton = {
    onClick,
    type,
  };

  if (_.includes(buttonTypes, type)) {
    /* eslint-disable-next-line react/button-has-type */
    return <button {...elementPropsButton}>{children}</button>;
  }

  return (
    <button className={classNames(unstyledButton, className)} {...elementPropsButton} type="button">
      {children}
    </button>
  );
};

Clickable.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inNewTab: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
  type: PropTypes.oneOf(buttonTypes),
};

Clickable.defaultProps = {
  className: '',
  disabled: false,
  inNewTab: false,
  onClick: _.noop,
  to: '',
  type: null,
};

export default Clickable;
